import { useRemainingJobSlots } from "../../../../hooks/my-subscription";

const SubscriptionRemainingJobPostCount = ({ subscribedPlan, showBadge = false }) => {
    const { canPost, availableCount, label } = useRemainingJobSlots(subscribedPlan);

    if (showBadge) {
        return (
            <div className="col-3 position-relative">
                {canPost !== undefined && (
                    <>
                        {/* Badge with count and text inside, with space between count and text */}
                        <span className="badge count-badge bg-soft-primary text-primary font-14">
                            <span className="badge-count">{canPost ? availableCount : 0}</span>
                            <span> </span>
                            <span className="badge-text">{label}</span>
                        </span>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="col-3">
            {canPost !== undefined && (
                <>
                    <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                        <p className="font-18 avatar-title text-primary">
                            {canPost ? availableCount : 0}
                        </p>
                    </div>
                    <p>{label}</p>
                </>
            )}
        </div>
    );
};

export default SubscriptionRemainingJobPostCount;