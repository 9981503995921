import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";

import PageTitle from "../../../Layout/PageTitle";
import PasswordToggle from "../../../../hooks/PasswordToggle";
import { phoneRegExp } from "../../../../utils/dateFormat";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
function JobRequestStep3() {
  const location = useLocation()
  let navigate = useNavigate();
  const user = tokenUtils.getTokenData();
  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/sudent/dashboard" },
    { text: "Candidates", link: "/sudent/dashboard" },
    // { text: "Add Candidate", link: null },
  ];
  const [documentLists, setDocumentLists] = useState();

  const fetchDocuments = async () => {
    try {
      const param = {
        user_id: user.id,
      };
      const result = await api.postMethod(param, "user/get-dashboard-data");

    if(result){

      setDocumentLists(result.data.resumes);
    };

      }
     catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setLoading(false); // Stop loading once data is fetched or an error occurs
    }
  };
  console.log(documentLists)

  useEffect(()=>{
    fetchDocuments()
  },[])
  const [selectedDocuments, setSelectedDocuments] = useState();

  const handleCheckboxChange = (docId) => {
    const updatedDocuments = selectedDocuments.includes(docId)
      ? selectedDocuments.filter((id) => id !== docId)
      : [...selectedDocuments, docId];

    setSelectedDocuments(updatedDocuments);
    console.log(selectedDocuments)
  }
  const handleSaveData = async()=>{
    try{
      const payload={
     user_id:user.id,
     job_request_id:location.state.id,
     user_document_id:selectedDocuments.user_document_id
       }
       const apiData = await api.postMethod(payload,
         "user/job-requests/store"
       );
       if(apiData){
        toast.success("Opportunity Successfully Saved")
        navigate("/user/jobrequest")
       }
     }
     catch(error){
       console.error("Error updating data:", error);
       navigate("/user/jobrequest")
     }
  }


  const handleGoBack=()=>{
    
    navigate("/user/job-request-step2",{state:{isUpdate:"update",id:location.state.id}})
  }
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <Link to="/user/jobrequest" className="breadcrumb-item active">Job Request</Link>
                      </ol>
                    </div>
                    <h4 className="page-title">Job Oppertunity</h4>
                  </div>
                </div>
              </div>
              {/* <PageTitle title="Add Candidate" breadcrumbItems={breadcrumbItems} /> */}

              {/*Job progress bar start*/}
              <div className="row mt-2">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location & Salary</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Attach Resume</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                  <h2 className="header-title text-uppercase m-auto mb-2 p-2">Attatchments</h2>
                  <div className="card-body" style={{display:"flex",justifyContent:"center"}}>

{documentLists&&documentLists!= ""?
                  <div>

                  {documentLists&&documentLists.map((document) => (<> <label key={document.id} class="me-2 d-block">
                        <input
                          type="checkbox"
                          name="user_document_id"
                          // checked={selectedDocuments.includes(document.id)}
                          onChange={() => setSelectedDocuments(document.id)}
                        />
                        {document.title}
                      </label>
                  

                      </>)
                  
                    )}
                  </div>:
                      <form
                        className="dropzone dz-clickable p-0 custom-file-upload-container position-relative"
                        id="resume"
                      >
                        <div className="dz-message needsclick">
                          <i className="h3 text-muted dripicons-cloud-upload" />
                          <h4>Drop or Click to Upload New Attatchments</h4>
                        </div>

                        <input
                          type="file"
                          name="file_name"
                          id="file_name"
                          accept="*"
                        />
                      </form>
}
                      {/* Preview */}
                      <div className="dropzone-previews mt-3 " id="file-previews" />
                     
                    </div>
                    <div className="p-3" style={{display:"flex",justifyContent:"space-between"}}>
<Button onClick={()=>handleGoBack()}>Back</Button>
              <Button onClick={()=>{handleSaveData() }}>Save and Continue <i className="fas fa-angle-right" /></Button>
                      </div>
                    {/* end card-body*/}
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/*Job progress bar end*/}
           
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default JobRequestStep3;
