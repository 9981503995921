import { create } from 'zustand';
import { apiRequest } from '../../services/ApiRequest';

const initialStore = {
  data: [],
  loading: false,
  error: null,
};

const useSubscriptionHistoryStore = create((set) => ({
 ...initialStore,

  fetch: async () => {
    set({ ...initialStore, loading: true });
    try {
      const response = await apiRequest("admin/subscription-history", "GET");
      if (response.success) {
        set({ data: response.data, loading: false });
      } else {
        if(response.status === 404){
          set(initialStore);
        } else {
          set({
            ...initialStore,
            error: response.message || "Failed to fetch subscription history",
          });
        }
      }
    } catch (err) {
      set({
        ...initialStore,
        error: err.message || "Network error occurred. Please check your connection.",
      });
      console.error("Fetch Subscription history Error:", err);
    }
  },

  refetch: () => {
    set({...initialStore, loading: true});
    setTimeout(() => {
      useSubscriptionHistoryStore.getState().fetch();
    }, 0);
  },

  reset: () => set(initialStore),
}));

export default useSubscriptionHistoryStore;