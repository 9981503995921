import React, { useState, useEffect } from "react";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as tokenUtils from "../../../../utils/tokenUtils";
import Job_Request_details from "./Tabs/Job_Request_details";
import Job_Oportunities from "./Tabs/Job_Oportunities";
import Job_Post_Match from "./Tabs/Job_Post_Match";
import * as api from "../../../../services/ApiService";
import moment from "moment";
import { Button } from "react-bootstrap";
import PageTitle from "../../../Layout/PageTitle";
import { currentRole } from "../../../Layout/HelmetComponent";

function JobUserDetails(props) {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const navigate=useNavigate()
  const [selectedTab, setSelectedTab] = useState("job_request");
  useEffect(() => {
    if (location.state?.tab) {
      setSelectedTab(location.state.tab);
    }
  }, [location.state]);
  const [skills, setSkills] = useState([]);
  const [states, setStates] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [shifts, setShifts] = useState([]);
  const { id } = useParams();
  console.log(id);
  //console.log("skills", skills);

  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };
  const [userDetail, setUserDetail] = useState();
  const userDetails = async () => {
    try {
      const payload = {
        // client_id:user.id,
        job_request_id: id,
      };
      const apiData = await api.postMethod(
        payload,
        "client/job-requests/get-job-request-details"
      );
      if (apiData) {
        setUserDetail(apiData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userDetails();
  }, []);

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: `/client/dashboard` },
    { text: "Job Request", link: "/client/job-request" },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
            <PageTitle    
                // title={isBoostedJobs ? "Boosted Jobs" : "Job Requests & Matches"}
                breadcrumbItems={breadcrumbItems}
              />
              <div className="row">
                <div className="col-lg-12 col-xl-12">
               
                  <h3>Job Request Details</h3>

                  <div className="card p-2 d-flex flex-row justify-content-between">
                    <div>
                      <h4>{
                        userDetail?.profile_pic?<img
                      style={{width:"4vw",borderRadius:"50%",marginRight:"1vw"}}
                  src={userDetail?.profile_pic}
                />:<img
                                            src={process.env.PUBLIC_URL + "/assets/images/fav-icon.jpg"}
                                            style={{width:"4vw",borderRadius:"50%",marginRight:"1vw"}}
                                          />
                      }
                      
                      {userDetail?.user_name}</h4>
                      <p>
                        Posted on :{" "}
                        {moment(userDetail?.created_at).format("MM/DD/YYYY")}
                      </p>
                    </div>
                    <div className='d-flex justify-content-end mt-4 mb-4 btn btn-submit'>
      <Button onClick={()=>navigate("/client/sent_job_request",{state:{jobRequest:userDetail?.id,userId:userDetail.user_id,UserData:userDetail}})}>Create Job Opportunity</Button>
    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb=3">
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_request"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_request")}
                          >
                            Job Request Details
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_opportunities"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_opportunities")}
                          >
                            Job Opportunities Sent
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_post_match"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_post_match")}
                          >
                            Job Post Matches
                          </button>
                        </div>
                        {/* 
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "skills" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("skills")}
                          >
                            Skills
                          </button>
                        </div>
                        */}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      {selectedTab === "job_request" && (
                        <Job_Request_details userDetail={userDetail} />
                      )}
                      {selectedTab === "job_opportunities" && (
                        <Job_Oportunities userDetails={userDetails} userDetail={userDetail} />
                      )}
                      {selectedTab === "job_post_match" && (
                        <Job_Post_Match userDetail={userDetail} />
                      )}
                    </div>
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
    </>
  );
}

export default JobUserDetails;
