import moment from 'moment'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import * as toast from "../../../../../utils/toastUtils";
const Job_Oportunities = ({userDetail,userDetails}) => {
  let user = tokenUtils.getTokenData();
    const [loading,setLoading] = useState(false)
    const [noRecordsFound,setNoRecordsFound]= useState(userDetail?.job_opportunities.length=="0"?true:false)
    const [jobOpportunities,setJobOppertunity] = useState(userDetail?.job_opportunities)
    const navigate = useNavigate()

    const deleteJobOppertunity =async(id)=>{
      try{
        const payload= {
          client_id:user.id,
          job_opportunity_id:id
        }
        const apiData = await api.postMethod(
          payload,
          "client/job-requests/delete-job-opportunity"
        );
        if(apiData){
          window.location. reload()
          toast.success("Oppertunity Deleted Successfully")
        }
      }
      catch(error){
        console.log(error)
      }
    }

  return (
    <div>
      <div className="table-responsive " style={{height:"20vw"}}>
                        <table className="table table-centered mb-0"  style={{height:"5vw"}}>
                          {/* table-nowrap */}
                          <thead className="table-light">
                            <tr>
                         
                              <th>&nbsp;</th>
                              <th width="15%">Job Title</th>
                              <th>Specialty</th>
                              <th>Status</th>
                              {/* 
                              <th>Boost</th>
*/}
                              <th>Date Posted</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="8">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {jobOpportunities&&jobOpportunities.length == 0 ? (
                                  <tr>
                                    <td colSpan="8">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {jobOpportunities &&
                                      Object.entries(jobOpportunities).map(([key, value]) => (
                                        <tr
                                         key={value.id}
                                         >
                                   
                                          <td>
                                            <span
                                            //   className={
                                            //     value.is_starred == 1
                                            //       ? "star-toggle fas fa-star text-warning"
                                            //       : "star-toggle far fa-star"
                                            //   }
                                            //   onClick={() =>
                                            //     toggleStarredJob(value.id, value.is_starred)
                                            //   }
                                            ></span>
                                          </td>
                                          <td>
                                            <Link
                                            //   to={`/` + urlSlug + `/job/` + value.unique_id}
                                              className="job-title"
                                            //   data-tooltip-id={`mt-` + value.unique_id}
                                            //   data-tooltip-content={value.title}
                                            >
                                              {value.title} 
                                            </Link>
                                            <Tooltip 
                                            // id={`mt-` + value.unique_id}
                                            ></Tooltip>

                                            <p className="mb-0 text-muted">
                                              {/* <small>(#{value.unique_id})</small> */}
                                            </p>
                                            {(value.state_code !== null ||
                                              value.city_name !== null) && (
                                              <p className="mb-0 ">
                                                <small>
                                                  <i className="fa fa-map-marker-alt me-1"></i>
                                                  {value.state_name},{value.city_name}
                                                </small>
                                              </p>
                                            )}
                                          </td>
                                      
                                          <td>
                                            {value.specialty !== null ? value.specialty : "-"}
                                            
                                          </td>
                                          <td>
                                            <h5>
                                             
                                              {value.user_response == "Not Interested" ? (
                                                <label className="badge badge-soft-danger">
                                               {value.user_response}
                                                </label>
                                              ):<label className="badge badge-soft-success">
                                                {value.user_response}
                                                </label>}
                                               {/* 
                                              {value.status == 0 && (
                                                <label className="badge badge-soft-secondary">
                                                  Draft
                                                </label>
                                              )}
                                              {value.status == 6 && (
                                                <label className="badge badge-soft-danger">
                                                  Expired
                                                </label>
                                              )} */}
                                            </h5>
                                          </td>

                                          {/* 
                                          <td>
                                            <button
                                              type="button"
                                              onClick={() => handleEdit(value)}
                                              className={
                                                value.is_boosted == 1
                                                  ? "btn btn-primary btn-sm waves-effect waves-light mb-2 mr-2"
                                                  : "btn btn-secondary btn-sm waves-effect waves-light mb-2 mr-2"
                                              }
                                            >
                                              Boost
                                            </button>
                                          </td>
                                          */}

                                          <td>
                                            {moment(value.created_at).format("MM/DD/YYYY")}{" "}
                                            <small className="text-muted">
                                              {/* {formatDate(value.created_at, "hh:mm A")} */}
                                            </small>
                                          </td>
                                          <td>
                                          <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>                                       
                                        <Dropdown.Item onClick={()=>navigate("/client/sent_job_request",{state:{job_opportunity_id:value.job_opportunity_id,send_job_opportunity_id:value.send_job_opportunity_id,UserData:userDetail}})} >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={()=>deleteJobOppertunity(value.job_opportunity_id)}>
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                          </td>
                                        </tr>
                                      ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
    </div>
  )
}

export default Job_Oportunities
