import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
import { getTokenData } from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";

const breadcrumbItems = [
  { text: "Admin Dashboard", link: "/admin/dashboard" },
  { text: "Announcements", link: null },
];

const labels = {
  // HOMEPAGE
  "HOMEPAGE": "Homepage", 

  // User-related items
  "USER_DASHBOARD": "User Dashboard Announcement",
  "USER_DOCUMENTS": "User Documents Announcement",
  "USER_SKILL_CHECKLIST": "User Skill Checklist Announcement",
  "USER_JOB_REQUEST": "User Job Request Announcement",

  // Agency-related items
  "AGENCY_DASHBOARD": "Agency Dashboard Announcement",
  "AGENCY_APPLICANTS": "Agency Applicants Announcement",
  "AGENCY_CANDIDATES": "Agency Candidates Announcement",
  "AGENCY_EMPLOYEES": "Agency Employees Announcement",
  "AGENCY_FOLLOW_UP": "Agency Follow Up Announcement",
  "AGENCY_OFFICE_ADMINS": "Agency Office Admins Announcement",
  "AGENCY_SKILL_CHECKLIST": "Agency Skill Checklist Announcement",
  "AGENCY_TASKS": "Agency Tasks Announcement",
  "AGENCY_FACILITY_COMPLIANCE_LIST": "Agency Facility Compliance List Announcement",
  "AGENCY_JOB_REQUEST": "Agency Job Request Announcement",

  // Facility-related items
  "FACILITY_DASHBOARD": "Facility Dashboard Announcement",
  "FACILITY_APPLICANTS": "Facility Applicants Announcement",
  "FACILITY_CANDIDATES": "Facility Candidates Announcement",
  "FACILITY_EMPLOYEES": "Facility Employees Announcement",
  "FACILITY_FOLLOW_UP": "Facility Follow Up Announcement",
  "FACILITY_OFFICE_ADMINS": "Facility Office Admins Announcement",
  "FACILITY_SKILL_CHECKLIST": "Facility Skill Checklist Announcement",
  "FACILITY_TASKS": "Facility Tasks Announcement",
  "FACILITY_COMPLIANCE": "Facility Compliance Announcement",
  "FACILITY_JOB_REQUEST": "Facility Job Request Announcement",

  // Admin-related items
  "DASHBOARD": "Dashboard Announcement"
};


const Announcements = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user = getTokenData();
  const [announcements, setAnnouncements] = useState([]);
  const [descriptions, setDescriptions] = useState({});

  const fetchAnnouncements = async () => {
    try {
      const param = { user_id: user.id };
      const apiData = await api.postMethod(param, "admin/get-announcements");
      setAnnouncements(apiData.data);
      const initialDescriptions = {};
      apiData.data.forEach((item) => {
        initialDescriptions[item.module] = item.description;
      });
      setDescriptions(initialDescriptions);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching announcements:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAnnouncements();
  }, []);

  const handleEditorChange = (roleId, data) => {
    setDescriptions((prevState) => ({
      ...prevState,
      [roleId]: data,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    // Prepare announcement data for submission
    const announcementData = announcements.map((announcement) => ({
      module: announcement.module,
      description: descriptions[announcement.module] || "",
    }));
  
    try {
      const param = {
        user_id: user.id,
        announcements: announcementData,
      };
      const result = await api.postMethod(param, "admin/add-edit-announcement");
      toast.success(result.message);
  
      // Fetch announcements again to refresh the view after submitting
      await fetchAnnouncements();
    } catch (error) {
      console.error("Error submitting announcements:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Layout>
      <PageTitle title="Announcements" breadcrumbItems={breadcrumbItems} />
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                {announcements.length > 0 && announcements.map((announcement, key) => (
                  <div className="form-group mb-3 col-md-12" key={key}>
                    <label htmlFor={`announcement-${announcement.module}`} className="form-label">
                      {labels[announcement.module]}
                    </label>
                    {announcement.module.includes("USER") || announcement.module.includes("HOMEPAGE") ? (
                      <>
                        <textarea
                          className="form-control"
                          onChange={(e) => handleEditorChange(announcement.module, e.target.value)}
                          rows={10}
                          value={descriptions[announcement.module] || ""}
                        ></textarea>
                      </>
                    ) : (
                      <>
                        <CKEditor
                          editor={ClassicEditor}
                          data={descriptions[announcement.module] || ""}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            handleEditorChange(announcement.module, data);
                          }}
                        />
                      </>
                    )}
                  </div>
                ))}
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-lg-end">
                      <button type="submit" className="btn btn-primary me-1">
                        Submit
                      </button>
                      <button className="btn btn-info" type="reset">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Spinner />}
    </Layout>
  );
};

export default Announcements;
