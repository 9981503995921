import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import { toast } from "react-toastify";
import moment from "moment";
import { formatAmount } from "../../../utils/AmountFormatter";
import Announcement from "../../UI/Announcement";

const JobRequset = () => {
  const [loading, setLoading] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [cityState, setCityState] = useState();
  const user = tokenUtils.getTokenData();

  let navigate = useNavigate();

  const [jobrequest, setjobrequest] = useState(1);
  const [jobRequestData, setJobRequestData] = useState("");
  const [activeStateCount, setActiveStateCount] = useState();
  const [getJobOppertunity,setGetJobOppertunity] = useState()
  const [response,setResponse] = useState()
  const getJobRequest = async () => {
    try {
      const payload = {
        user_id: user.id,
        status: jobrequest,
      };
      const apiData = await api.postMethod(
        payload,
        "user/job-requests/get-active-request"
      );
      if (apiData) {
        // console.log(apiData.data)
        setActiveStateCount(apiData?.data?.active_job_request);
        setJobRequestData(apiData.data.job_requests);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getJobRequest();
  }, [jobrequest]);

  const getJobRecived = async()=>{
    try {
      const payload = {
        user_id: user.id,
        // status: jobrequest,
      };
      const apiData = await api.postMethod(
        payload,
        "user/job-requests/get-received-job-opportunities"
      );
      if (apiData) {
        setGetJobOppertunity(apiData.data)
        // console.log(apiData.data)
        // setActiveStateCount(apiData?.data?.active_job_request);
        // setJobRequestData(apiData.data.job_requests);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(()=>{
getJobRecived()
  },[])

  const sendCreatePage = () => {
    if (activeStateCount >= 1) {
      toast.error("You already have one Active Job Request. Please update or mark Inactive");
    } else {
      navigate("/user/job-request-step1");
    }
  };
  const handleInActive = async () => {
    try {
      const payload = {
        user_id: user.id,
        job_request_id: jobRequestData[0].id,
        status: 0,
      };
      const apiData = await api.postMethod(payload, "user/job-requests/store");
      if (apiData) {
        getJobRequest();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleActive = async (id) => {
    if (activeStateCount >= 1) {
      toast.error("You already have one Active Job Request. Please update or mark Inactive");
    } else {
      try {
        const payload = {
          user_id: user.id,
          job_request_id: id,
          status: 1,
        };
        const apiData = await api.postMethod(
          payload,
          "user/job-requests/store"
        );
        if (apiData) {
          // getJobRequest();
          setjobrequest(1)
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const DisplayValues = (values, maxDisplay = 3) => {
    // console.log(values);
    if (!values || values.length === 0) return <span>No Location Found</span>;
    const displayText =
      values.length <= maxDisplay
        ? values.join(", ")
        : values.slice(0, maxDisplay).join(", ") + ", ...";
    return displayText;
  };
  const [openStateModal, setOpenStateModal] = useState(false);
  const [openStateModal2, setOpenStateModal2] = useState(false);
  const [opportunityId,setOppertunityId] = useState()
  const toggleStateModal = () => {
    setOpenStateModal(!openStateModal);

    if (!openStateModal) {
      // Reset validation errors
      // setErrors({});
    }
  };
  const toggleStateModal2 = () => {
    setOpenStateModal2(!openStateModal2);

    if (!openStateModal2) {
      // Reset validation errors
      // setErrors({});
    }
  };
const deleteJobOppertunity = async(value)=>{
  try{
    const payload={
      user_id:user.id,
      send_job_opportunity_id:value.send_job_opportunity_id
    }
    const apiData = api.postMethod(payload,"user/job-requests/delete-job-opportunity")
    if(apiData){
      toast.success("Job opportunity Successfully Deleted ");
      getJobRecived()
    }
  }
  catch(error){
    console.log(error)
  }
}

 const sendResponse=async()=>{
  try{
    const payload = {
      user_id:user.id,
      send_job_opportunity_id:opportunityId,
      response:response
    }
    const apiResponse = await api.postMethod(payload,"user/job-requests/respond-job-opportunity")
    if(apiResponse.status == true){
    if(response=="Interested"){
      toast.success("Thanks for your reponse! A Recruiter will be in touch shortly");
    }else{

      toast.success(apiResponse.message);
    }
      toggleStateModal2()
      getJobRecived()
    }
  }
  catch(error){
    console(error)
  }
 }

 function addCommas(input) {
  // Convert the input to a string in case it's a number
  const text = input.toString();
  const words = text.split(' ');
  if (words.length <= 3) {
      return words.join(' '); // If the text has 3 or fewer words, no commas are added
  }

  // Add comma after the first 3 words
  const result = [words.slice(0, 3).join(' ')]; // First 3 words

  // Add comma after every 2 words thereafter
  for (let i = 3; i < words.length; i += 2) {
      result.push(words.slice(i, i + 2).join(' '));
  }

  return result.join(', ');
}

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
          <Announcement module={"USER_JOB_REQUEST"} />
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Job Request</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Jobs Request</h4>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setjobrequest(e.target.value)}
                    value={jobrequest}
                  >
                    <option value="1">Active Job Request</option>
                    <option value="0">InActive Job Request</option>
                  </select>
                </div>
                <div>
                  <Button
                    onClick={() => sendCreatePage()}
                    className="btn btn-primary waves-effect waves-light mb-2"
                  >
                    <i className="fa fa-plus mr-1" /> New Job Request
                  </Button>
                </div>
              </div>

              {/* end row */}
            </div>
            {/* container */}
            <div className=" mt-4">
              {/* Card */}
              {jobrequest == 1 ? jobRequestData.length==1?(
                <div className="card border-0 custom-box-shadow">
                  <div className="d-flex">
                    <div>
                      <h4
                        className="mb-0 mr-2 p-1 pt-2"
                        style={{ marginLeft: "1.5vw" }}
                      >
                        Active Job Request
                      </h4>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* Title */}
                    <div className="d-flex align-items-baseline mb-2">
                      <h5 className="card-title" style={{ marginRight: 3 }}>
                        Current Job Request Details
                      </h5>
                      <span className="badge bg-success">Active</span>
                    </div>

                    {/* Job Details */}
                    <div className="row d-flex  justify-content-between">
                      <div className="col-md-4">
                        <p className="mb-1">
                          <strong>Profession:</strong>{" "}
                          {jobRequestData[0]?.profession}
                        </p>
                        <p className="mb-1">
                          <strong>Specialty:</strong>{" "}
                          {jobRequestData[0]?.specialty}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-1">
                          <strong>Shift:</strong>{" "}
                          {jobRequestData[0]?.shift_title}
                        </p>
                        
                        <p className="mb-1 d-flex">
                          
                            <strong>Locations: </strong> 
                            <p>
                            &nbsp;
                            {jobRequestData[0]?.state_city_data[0]?.state} |{" "}
                            {DisplayValues(
                              jobRequestData[0]?.state_city_data[0]?.cities
                            )}{" "}
                            {jobRequestData[0]?.state_city_data.length > 1 ? (
                              <span
                                onClick={() => {
                                  setCityState(jobRequestData[0]);
                                  toggleStateModal();
                                }}
                                style={{
                                  marginLeft: "2vw",
                                  background: "#43bfe5",
                                  padding: 3,
                                  fontSize: "12px",
                                  borderRadius: 50,
                                  color: "white",
                                  cursor: "pointer",
                                }}
                              >
                                +{jobRequestData[0]?.state_city_data.length - 1}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                          
                           
                          
                        </p>
                          
                      </div>
                      <div className="col-md-3">
                        <p className="mb-1">
                          <strong>Salary:</strong> $
                          {formatAmount(jobRequestData[0]?.pay_rate)}{" "}
                          {jobRequestData[0]?.pay_type}
                        </p>
                        <p className="mb-1">
                          <strong>Start Date:</strong>{" "}
                          {jobRequestData[0]?.start_date}
                        </p>
                      </div>
                      <div className="col-md-1">
                        <Dropdown className="">
                          <Dropdown.Toggle
                            variant="default"
                            // id={`dropdown-basic-` + value.job_id}
                            // key={`dropdown-basic-` + value.job_id}
                            className="p-0"
                          >
                            <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                navigate("/user/job-request-step1", {
                                  state: {
                                    isUpdate: "update",
                                    id: jobRequestData[0].id,
                                  },
                                })
                              }
                            >
                              Update
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleInActive()}>
                              Mark as In-Active
                            </Dropdown.Item>
                            {/*                                         
                                        
                                          <Dropdown.Item >
                                            Mark as In-Active
                                          </Dropdown.Item>
                                       

                                        <Dropdown.Item
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          Delete
                                        </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    {/* Ellipsis Menu */}
                  </div>
                </div>
              ):"" :  jobRequestData.length>=1?(
                <div className="card border-0 custom-box-shadow">
                  <div className="d-flex ">
                    <div>
                      <h4 className="mb-0 mr-2 p-1 pt-2">
                        In-Active Job Request
                      </h4>
                    </div>
                  </div>
                  {jobRequestData &&
                    jobRequestData.map((data) => (
                      <div
                        className="card-body"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        {/* Title */}
                        <div className="d-flex align-items-baseline mb-3">
                          <h5
                            className="card-title "
                            style={{ marginRight: 3 }}
                          >
                            In-Active Job Request Details
                          </h5>
                          <span className="badge bg-danger ml-1">
                            In-Active
                          </span>
                        </div>

                        {/* Job Details */}
                        <div className="row d-flex justify-content-between">
                          <div className="col-md-4">
                            <p className="mb-1">
                              <strong>Profession:</strong> {data?.profession}
                            </p>
                            <p className="mb-1">
                              <strong>Specialty:</strong> {data?.specialty}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p className="mb-1">
                              <strong>Shift:</strong> {data?.shift_title}
                            </p>
                            <p className="mb-1">
        <strong>Locations:</strong>
        <span id="tooltip-target" style={{ cursor: "pointer" }}>
          <strong>{data?.state_city_data[0]?.state}</strong> |{" "}
          {DisplayValues(data?.state_city_data[0]?.cities)}{" "}
          {data?.state_city_data.length > 1 ? (
            <span
              onClick={() => {
                setCityState(data);
                toggleStateModal();
              }}
              style={{
                marginLeft: "2vw",
                background: "#43bfe5",
                padding: 3,
                fontSize: "12px",
                borderRadius: 50,
                color: "white",
                cursor: "pointer",
              }}
            >
              +{data?.state_city_data.length - 1}
            </span>
          ) : (
            ""
          )}
        </span>
      </p>
      {/* Tooltip Component */}
      <Tooltip
        anchorId="tooltip-target"
        content="Send Message"
        place="top"
      />
                            <Tooltip id="mt-1"></Tooltip>
                          </div>
                          <div className="col-md-3">
                            <p className="mb-1">
                              <strong>Salary:</strong> ${formatAmount(data?.pay_rate)}{" "}
                              {data?.pay_type}
                            </p>
                            <p className="mb-1">
                              <strong>Start Date:</strong> {data?.start_date}
                            </p>
                          </div>
                          <div className="col-md-1">
                            <Dropdown className="">
                              <Dropdown.Toggle
                                variant="default"
                                // id={`dropdown-basic-` + value.job_id}
                                // key={`dropdown-basic-` + value.job_id}
                                className="p-0"
                              >
                                <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    navigate("/user/job-request-step1", {
                                      state: {
                                        isUpdate: "update",
                                        id: data.id,
                                      },
                                    })
                                  }
                                >
                                  Update
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleActive(data.id)}
                                >
                                  Mark as Active
                                </Dropdown.Item>
                                {/*                                         
                                        
                                          <Dropdown.Item >
                                            Mark as In-Active
                                          </Dropdown.Item>
                                       

                                        <Dropdown.Item
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          Delete
                                        </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        {/* Ellipsis Menu */}
                      </div>
                    ))}
                </div>
              ):""}
            </div>
            <div className="row">
              <div className="col-12">
                {loading ? (
                  <div className="card mb-3 custom-box-shadow">
                    <div className="card-body">Loading...</div>
                  </div>
                ) : (
                  <>
                    {noRecordsFound ? (
                      <div className="card mb-3 custom-box-shadow">
                        <h4 style={{ fontWeight: "700", padding: "1vw" }}>
                          Jobs Received
                        </h4>
                        <div className="card-body">No record found</div>
                      </div>
                    ) : (
                      <>{getJobOppertunity&&getJobOppertunity.length >=1? <h4 style={{ fontWeight: "700", padding: "1vw" }}>
                          Jobs Received
                        </h4>:""}
                       

                        {getJobOppertunity&&Object.entries(getJobOppertunity).map(([key, value]) => (
                        <div
                          className="card mb-3 custom-box-shadow  position-relative"
                          key={value.id}
                        >
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-sm-12">
                                <h5 className="mt-0 mb-2 font-16">
                                  <Link to={`${value.id}`} className="">
                                    {value.company_name}
                      {value.job_opportuniy_status== "Not Interested"?<span style={{marginLeft:"1vw"}} className="badge bg-danger ml-1">{value.job_opportuniy_status} </span>:<span style={{marginLeft:"1vw"}} className="badge bg-success">{value.job_opportuniy_status}</span>}
                                    
                                  </Link>
                                 
                                  {/* 
                                      <span className="mb-0 text-muted">
                                        <small>(#{value.unique_id})</small>
                                      </span>
                                      */}
                                </h5>
                              </div>
                              <div className="col-sm-4">
                                <div className="d-flex align-items-start">
                                  <Link to={`/user/job/` + 1} className="">
                                    {/* {value.profile_pic_path && ( */}
                                    {value.profile_pic==null?<img
                                            src={process.env.PUBLIC_URL + "/assets/images/fav-icon.jpg"}
                                            className="d-flex align-self-center me-3 rounded-circle"
                                            alt="user-img"
                                            height={64}
                                            width={64}
                                          />:<img
                                      src={value.profile_pic}
                                      className="d-flex align-self-center me-3 rounded-circle"
                                      alt="user-img"
                                      height={64}
                                    />}
                                    
                                  </Link>

                                  <div className="w-100">
                                    <p className="mb-1">
                                      <b>Profession: </b>{" "}
                                      {value.profession}
                                    </p>
                                    <p className="mb-0">
                                      <b>Specialty: </b>{" "}
                                      {value.specialty !== null ? value.specialty : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <p className="mb-1 mt-0 mt-sm-0">
                                  <b>Shift: </b>
                                  {value.shift_title || "-"}
                                </p>
                                <p className="mb-0">
                                  <b>Locations: </b>
                                   {value.city_name} | {value.state_name} 
                                </p>
                              </div>
                              <div className="col-sm-3">
                                <p className="mb-1 mt-0 mt-sm-0">
                                  <b>Salary: </b>
                                  ${formatAmount(value.pay_rate) || "N/A"} {value.pay_type || "N/A"}
                                </p>
                                <p className="mb-0">
                                  <b>Start Date: </b>
                                  {moment(value.start_date).format("MM/DD/YYYY")}
                                </p>
                              </div>
                              <div className="col-md-1">
                                <Dropdown className="">
                                  <Dropdown.Toggle
                                    variant="default"
                                    // id={`dropdown-basic-` + value.job_id}
                                    // key={`dropdown-basic-` + value.job_id}
                                    className="p-0"
                                  >
                                    <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={()=>deleteJobOppertunity(value)}>Delete</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>toggleStateModal2(setOppertunityId(value.send_job_opportunity_id))}>Respond</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          {/* {value.is_boosted == 1 && (
                                <div className="ribbon ribbon-triangle ribbon-top-end border-primary">
                                  <div class="ribbon-icon mt-n3 me-0">
                                    <i class="fas fa-star fs-2 text-white font-18"></i>
                                  </div>
                                </div>
                              )} */}
                        </div>

                        ))}
                      </>
                    )}
                  </>
                )}

                {/* end card*/}
                {/* end card*/}
              </div>
              {/* end col */}
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <Modal
        show={openStateModal}
        onHide={toggleStateModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">State / Cities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
              <p>
                <strong style={{ marginBottom: "2vw" }}>Locations</strong>
              </p>
              {cityState &&
                cityState?.state_city_data.map((data) => (
                  <>
                    <p>
                      <strong>{data.state}</strong> |{" "}
                      {data && data.cities.map((city) => <span> {city},</span>)}
                    </p>
                  </>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openStateModal2}
        onHide={toggleStateModal2}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Respond</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
                <select
                className="form-control mb-4"
                onChange={(e)=>setResponse(e.target.value)}
                >
                  <option>Please Select</option>
                  <option value="Interested">Interested</option>
                  <option value="Not Interested">Not Interested</option>
                </select>
            </div>
          </div>
          <div className="d-flex" style={{alignItems:"center",justifyContent:"center"}}>
          <Button onClick={()=>sendResponse()}>
            Send Response
          </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobRequset;
