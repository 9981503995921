import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import Spinner from "../../../Loader/Spinner";
import PageTitle from "../../../Layout/PageTitle";
import { currentRole } from "../../../Layout/HelmetComponent";
import { toast } from "react-toastify";

function JobPostStep5() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobID } = useParams();
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const location = useLocation();
  const [jobUniID, setJobID] = useState(location?.state?.unique_id);

  const fetchDraftJobs = async () => {
    try {
      const param = {
        step: "step5",
        user_id: user.id,
        token: user.token,
        job_id: localStorage.getItem("job_id"),
      };
      const apiData = await api.postMethod(param, "admin/get-draft-job");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);
      if (apiData.status || localStorage.getItem("job_id")) {
        localStorage.removeItem("job_id");
        if (apiData?.data?.unique_id) setJobID(apiData.data.unique_id);
      } else {
        navigate("/admin/job-post-step1");
      }
      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      //setWorkHisstories(apiDataObj);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDraftJobs();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Jobs", link: "/admin/job-postings" },
    { text: "Post a Job", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Post a Job" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location &amp; Salary</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">3</div>
                      <div className="step-name">Qualification &amp; Responsibilities</div>
                    </div>
                    {/* <div className="stepper-item completed">
                      <div className="step-counter">4</div>
                      <div className="step-name">Attatchments</div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="text-center">
                        <i className="dripicons-checkmark h1 display-3 text-success" />
                        <h4 className="mt-2 text-dark display-4">Well Done!</h4>
                        <p className="mt-3 lead">
                          The Job #{jobUniID} is live on the platform now. You can edit the job at
                          any time.
                        </p>
                        <Link to="/admin/job-postings" className="btn btn-primary my-2 me-2">
                          Manage Jobs
                        </Link>
                        <Link to={"/admin/job/" + jobUniID} className="btn btn-info my-2">
                          View on Platform
                        </Link>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default JobPostStep5;
