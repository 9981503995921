import { useEffect } from "react";
import { formatDateString } from "../../../../utils/dateFormat";
import useSubscriptionHistoryStore from "../../../../store/useSubscriptionHistoryStore";
import { getBillingCycleLabel } from "../../../../utils/billingCycleHelper";
import Spinner from "../../../Loader/Spinner";

const SubscriptionHistoryComponent = () => {
    const { data, fetch, loading } = useSubscriptionHistoryStore();

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <div className="card-body custom-box-shadow p-2">
            <div className="row">
                <div className="col-12 mb-3">
                    <p className="text-start">Plans History</p>
                </div>
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Plan Name</th>
                                    <th>Price</th>
                                    <th>Billing Cycle</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Renewal Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && <Spinner />}
                                {data && data.length > 0 ? (
                                    data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.plan.name}</td>
                                            <td>{item.billing_cycle.price ? `$${item.billing_cycle.price}` : 'NA'}</td>
                                            <td>{getBillingCycleLabel(item.billing_cycle.type, undefined, item.plan.type === 'trial') ?? 'NA'}</td>
                                            <td>{formatDateString(item.start_date)}</td>
                                            <td>{formatDateString(item.end_date)}</td>
                                            <td>{formatDateString(item.renewal_date)}</td>
                                            <td>{item.status}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            No subscription history available.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionHistoryComponent;