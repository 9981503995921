import React from 'react'
import SubscriptionList from './SubscriptionList';

const ActiveSubscriptions = ({data}) => {
  const filteredData = data.filter((subscription) => subscription.status === "active");
  return (
    <SubscriptionList data={filteredData} />
  )
}

export default ActiveSubscriptions;