import { create } from 'zustand';
import { apiRequest } from "../services/ApiRequest";
import { toast } from 'react-toastify';

const initialStore = {
  data: null,
  loading: false,
  error: null
};

const useSubscriptionStore = create((set) => ({
  ...initialStore,

  fetch: async () => {
    set({ ...initialStore, loading: true });
    try {
      const response = await apiRequest("client/subscription", "GET");
      if (response.success) {
        set({ data: response.data, loading: false });
      } else {
        if(response.status === 404){
          toast.info("Get a subscription");
          set(initialStore);
        } else {
          set({
            ...initialStore,
            error: response.message || "Failed to fetch my subscription",
          });
        }
      }
    } catch (err) {
      set({
        ...initialStore,
        error: err.message || "Network error occurred. Please check your connection.",
      });
      console.error("Fetch my subscription Error:", err);
    }
  },

 
  refetch: () => {
    set({ ...initialStore, loading: true });
    setTimeout(() => {
      useSubscriptionStore.getState().fetch();
    }, 0);
  },

  reset: () => set(initialStore),
}));

export default useSubscriptionStore;
