import React, { useState, useEffect } from "react";
import * as profile from "../../../../services/ProfileService";
import * as Yup from "yup";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { phoneRegExp } from "../../../../utils/dateFormat";
import Spinner from "../../../Loader/Spinner";

function PersonalInfo({ states, fetchProfileData }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    user_id: user.id,
    profile_pic: null,
    name: null,
    company_name: null,
    email: null,
    phone: null,
    website: null,
    primary_industry: "",
    company_size: "",
    bio: "",
    address_line1: "",
    address_line2: "",
    country_code: 1,
    city_id: null,
    state_id: 0,
    founded_in: null,
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    linkedin_url: "",
  });

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const param = {
        user_id: user.id,
      };
      const apiData = await profile.getPersonalInfo(param, "client/get-profile");

      setformData({
        user_id: user.id,
        profile_pic: apiData[0].profile_pic,
        name: apiData[0].name,
        company_name: apiData[0].company_name,
        email: apiData[0].email,
        phone: apiData[0].phone,
        website: apiData[0].website,
        primary_industry: apiData[0].primary_industry,
        company_size: apiData[0].company_size,
        bio: apiData[0].bio,
        address_line1: apiData[0].address_line1,
        address_line2: apiData[0].address_line2,
        state_id: apiData[0].state_id,
        city_id: apiData[0].city_id,
        founded_in: apiData[0].founded_in,
        facebook_url: apiData[0].facebook_url,
        twitter_url: apiData[0].twitter_url,
        instagram_url: apiData[0].instagram_url,
        linkedin_url: apiData[0].linkedin_url,
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  // Define the validation schema
  let validationSchema = Yup.object({
    name: Yup.string().required("Full Name is Required"),
    email: Yup.string().email("Invalid Email").required("Email is Required"),
    phone: Yup.string()
      .required("Phone Number is Required"),
    city_id: Yup.string().required("City is Required"),
    state_id: Yup.string().required("State is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Create form data object
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      let res = await profile.updatePersonalInfo(formDataToSend, "client/update-profile");
      if (res) {
        // Reset the profile_pic field to null
        setformData({
          ...formData,
          profile_pic: null,
        });
        document.getElementById("profile_pic").value = "";

        // Call the fetchData function when the component mounts
        fetchData();
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  // Handle change event
  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData({
      ...formData,
      [name]: value,
    });

    validateFields(name, value);
  };

  const handleImageChange = (e) => {
    setformData({
      ...formData,
      profile_pic: e.target.files[0], // Update image field with the selected file
    });
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  // Generate an array of years
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= currentYear - 15; year--) {
    years.push(year);
  }
  const formatInput = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters

    // Format input as XXX-XXX-XX
    if (inputValue.length <= 3) {
      inputValue = inputValue;
    } else if (inputValue.length <= 6) {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`;
    } else {
      inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
    }

    // Set formatted value back to state
    setformData({
      ...formData,
      ["phone"]: inputValue,
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="profile_pic" className="form-label">
              Logo
            </label>

            <div className="input-group">
              <input
                type="file"
                className="form-control"
                id="profile_pic"
                name="profile_pic"
                onChange={handleImageChange}
                accept="image/*"
              />
              {formData.profile_pic !== "" && (
                <div className="input-group-prepend">
                  <span className="input-group-text p-1">
                    <img src={formData.profile_pic} alt="" className="float-end " height={32} />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              onChange={handleChange}
              defaultValue={formData.name}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="company_name" className="form-label">
              Company Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="company_name"
              name="company_name"
              onChange={handleChange}
              defaultValue={formData.company_name}
            />
            {errors.company_name && <span className="error">{errors.company_name}</span>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              onChange={handleChange}
              defaultValue={formData.email}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone Number
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">+1</span>
              </div>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                onChange={(e)=>formatInput(e,"number")}
                value={formData.phone}
              />
            </div>
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="website" className="form-label">
              Website
            </label>
            <input
              type="text"
              className="form-control"
              pattern=".*(\.com|\.in)$"
              id="website"
              name="website"
              onChange={handleChange}
              defaultValue={formData.website}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="primary_industry" className="form-label">
              Primary Industry
            </label>
            <input
              type="text"
              className="form-control"
              id="primary_industry"
              name="primary_industry"
              onChange={handleChange}
              defaultValue={formData.primary_industry=="null"?"":formData.primary_industry}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="company_size" className="form-label">
              Company Size
            </label>
            <input
              type="text"
              className="form-control"
              id="company_size"
              name="company_size"
              onChange={handleChange}
              defaultValue={formData.company_size=="null"?"":formData.company_size}
            />
          </div>
        </div>
        {/* end col */}
      </div>
      {/* end row */}
      <div className="row">
        <div className="col-12">
          <div className="mb-3">
            <label htmlFor="bio" className="form-label">
              About Company
            </label>
            <textarea
              className="form-control"
              id="bio"
              rows={5}
              name="bio"
              onChange={handleChange}
              defaultValue={formData.bio=="null"?"":formData.bio}
            />
          </div>
        </div>
        {/* end col */}
      </div>
      {/* end row */}

      {/* end row */}
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="address_line1" className="form-label">
              Address Line 1
            </label>
            <input
              type="text"
              className="form-control"
              id="address_line1"
              name="address_line1"
              onChange={handleChange}
              defaultValue={formData.address_line1=="null"?"":formData.address_line1}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="address_line2" className="form-label">
              Address Line 2
            </label>
            <input
              type="text"
              className="form-control"
              id="address_line2"
              name="address_line2"
              onChange={handleChange}
              defaultValue={formData.address_line2=="null"?"":formData.address_line2}
            />
          </div>
        </div>
        {/* end col */}
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="state_id" className="form-label">
              State
            </label>
            <select
              className="form-control"
              name="state_id"
              onChange={handleChange}
              value={formData.state_id !== null ? formData.state_id : ""}
            >
              <option value="" key="">
                Please Select
              </option>
              {/* Render the states */}
              {states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>

            {errors.state_id && <span className="error">{errors.state_id}</span>}
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="city_id" className="form-label">
              City
            </label>
            <select
              className="form-control"
              name="city_id"
              onChange={handleChange}
              value={formData.city_id !== null ? formData.city_id : ""}
            >
              <option value="" key="">
                Please Select
              </option>
              {/* Render the states */}
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.city_name}
                </option>
              ))}
            </select>
            {errors.city_id && <span className="error">{errors.city_id}</span>}
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="founded_in" className="form-label">
              Founded In
            </label>
            <select
              className="form-control"
              id="founded_in"
              name="founded_in"
              onChange={handleChange}
              value={formData.founded_in !== null ? formData.founded_in : ""}
            >
              <option value="" key="">
                -
              </option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h5>Social Links</h5>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="facebook_url" className="form-label">
              Facebook
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text p-2">
                  <i className="fe-facebook"></i>
                </span>
              </div>
              <input
                type="url"
                className="form-control"
                id="facebook_url"
                name="facebook_url"
                onChange={handleChange}
                defaultValue={formData.facebook_url=="null"?"":formData.facebook_url}
                placeholder="Enter your Facebook URL"
              />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="twitter_url" className="form-label">
              Twitter
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text p-2">
                  <i className="fe-twitter"></i>
                </span>
              </div>
              <input
                type="url"
                className="form-control"
                id="twitter_url"
                name="twitter_url"
                onChange={handleChange}
                defaultValue={formData.twitter_url=="null"?"":formData.twitter_url}
                placeholder="Enter your Twitter URL"
              />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="instagram_url" className="form-label">
              Instagram
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text p-2">
                  <i className="fe-instagram"></i>
                </span>
              </div>
              <input
                type="url"
                className="form-control"
                id="instagram_url"
                name="instagram_url"
                onChange={handleChange}
                defaultValue={formData.instagram_url=="null"?"":formData.instagram_url}
                placeholder="Enter your Instagram URL"
              />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="linkedin_url" className="form-label">
              Linkedin
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text p-2">
                  <i className="fe-linkedin"></i>
                </span>
              </div>
              <input
                type="url"
                className="form-control"
                id="linkedin_url"
                name="linkedin_url"
                onChange={handleChange}
                defaultValue={formData.linkedin_url=="null"?"":formData.linkedin_url}
                placeholder="Enter your Linkedin URL"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-end">
        <button type="submit" className="btn btn-primary waves-effect waves-light mt-2">
          Update Profile
        </button>
      </div>
      {isLoading === true && <Spinner />}
    </form>
  );
}

export default PersonalInfo;
