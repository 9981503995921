import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";

import { Link, useLocation } from "react-router-dom";
import SubscriptionList from "./Tabs/SubscriptionList";
import ActiveSubscriptions from "./Tabs/ActiveSubscriptions";
import MoniterSubscription from "./Tabs/MoniterSubscription";
import useSubscriptionHistoryStore from "../../../store/admin/useSubscriptionHistoryStore";
import Spinner from "../../Loader/Spinner";

function Subscriptions() {
  const {data, fetch, loading} = useSubscriptionHistoryStore();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("all_subscriptions");
  const tabList = [
    { id: "all_subscriptions", label: "All Subscriptions" },
    { id: "active_subscriptions", label: "Active Subscriptions" },
    { id: "moniter_subscriptions", label: "Moniter Subscription" }
  ];

  useEffect(() => {
    fetch?.();
  }, [fetch]);

  useEffect(() => {
    if (location.state?.tab) {
      setSelectedTab(location.state.tab);
    }
  }, [location.state]);

  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const renderTabContent = {
    all_subscriptions: <SubscriptionList data={data} />,
    active_subscriptions: <ActiveSubscriptions data={data} />,
    moniter_subscriptions: <MoniterSubscription data={data} />
  };

  if(loading) return <Spinner />;

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">All Subscriptions</li>
                      </ol>
                    </div>
                    <h4 className="page-title">All Subscriptions</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  <div className="card">
                    <div className="card-body"></div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb=3">
                        {tabList.map((tab) => (
                          <div key={tab.id} className="col">
                            <button
                              type="button"
                              className={`btn w-100 ${selectedTab === tab.id ? 'btn-primary' : 'btn-light'}`}
                              onClick={() => handleTabSelect(tab.id)}
                            >
                              {tab.label}
                            </button>
                          </div>
                        ))}                          
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      {renderTabContent[selectedTab]}                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default Subscriptions;
