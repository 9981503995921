import moment from 'moment/moment'
import React, { useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as tokenUtils from "../../../../../utils/tokenUtils";
import * as api from "../../../../../services/ApiService";
import { toast } from 'react-toastify'
const Job_Post_Match = ({userDetail}) => {
  const navigate = useNavigate()
  let user = tokenUtils.getTokenData();
    const [loading,setLoading] = useState(false)
    const [noRecordsFound,setNoRecordsFound]= useState(userDetail?.job_post_matched==null?true:false)
    const [postMatched,setPostMatched] = useState(userDetail?.job_post_matched)
    const sendMatchedOppertunity = async(value)=>{
      try{
        const payload ={
          client_id:user.id,
          user_id:value.user_id,
          job_request_id:value.id,
          job_id:value.job_id
        }
        const apiData = await api.postMethod(payload,"client/job-requests/send-job-opportunity");
        if(apiData){
          // console.log(apiData.data)
        toast.success("Job Request Send Successfully")
  
          // getMatchedJobRequest()
        }
      }
      catch(error){
        console.log(error)
      }
    }
  return (
    <div>
   
      <div className="table-responsive " style={{height:"20vw"}}>
                        <table className="table table-centered mb-0"  style={{height:"5vw"}}>
                          {/* table-nowrap */}
                          <thead className="table-light">
                            <tr>
                         
                              <th>&nbsp;</th>
                              <th width="10%">Job Title</th>
                              <th>Specialty</th>
                              <th>Status</th>
                              {/* 
                              <th>Boost</th>
*/}
                              <th>Date Posted</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="8">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="8">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {/* {postMatched &&
                                      Object.entries(postMatched).map(([key, value]) => ( */}
                                        <tr
                                        //  key={value.id}
                                         >
                                   
                                          <td>
                                            <span
                                            //   className={
                                            //     value.is_starred == 1
                                            //       ? "star-toggle fas fa-star text-warning"
                                            //       : "star-toggle far fa-star"
                                            //   }
                                            //   onClick={() =>
                                            //     toggleStarredJob(value.id, value.is_starred)
                                            //   }
                                            ></span>
                                          </td>
                                          <td>
                                            <Link
                                            //   to={`/` + urlSlug + `/job/` + value.unique_id}
                                              className="job-title"
                                            //   data-tooltip-id={`mt-` + value.unique_id}
                                            //   data-tooltip-content={value.title}
                                            >
                                              {postMatched?.title} 
                                            </Link>
                                            <Tooltip 
                                            // id={`mt-` + value.unique_id}
                                            ></Tooltip>

                                            <p className="mb-0 text-muted">
                                              {/* <small>(#{value.unique_id})</small> */}
                                            </p>
                                            {/* {(value.state_code !== null ||
                                              value.city_name !== null) && (
                                              <p className="mb-0 ">
                                                <small>
                                                  <i className="fa fa-map-marker-alt me-1"></i>
                                                  {value.state_code !== null &&
                                                  value.city_name !== null
                                                    ? value.city_name + ", " + value.state_code
                                                    : value.state_code !== null
                                                    ? value.state_code
                                                    : value.city_name !== null
                                                    ? value.city_name
                                                    : ""}
                                                </small>
                                              </p>
                                            )} */}
                                          </td>
                                      
                                          <td>
                                            {userDetail?.specialty !== null ? userDetail?.specialty : "-"}
                                            
                                             {/* Specialities */}
                                          </td>
                                          <td>
                                            <h5>
                                              {/* {value.status == 1 && (
                                                <label className="badge badge-soft-success">
                                                  Active
                                                </label>
                                              )}
                                              {value.status == 2 && (
                                                <label className="badge badge-soft-danger">
                                                  In-Active
                                                </label>
                                              )}
                                              {value.status == 0 && (
                                                <label className="badge badge-soft-secondary">
                                                  Draft
                                                </label>
                                              )}
                                              {value.status == 6 && (
                                                <label className="badge badge-soft-danger">
                                                  Expired
                                                </label>
                                              )} */}
                                              {postMatched.user_response == "Not Interested" ? (
                                                <label className="badge badge-soft-danger">
                                               {postMatched.user_response}
                                                </label>
                                              ):<label className="badge badge-soft-success">
                                                {postMatched.user_response}
                                                </label>}
                                            </h5>
                                          </td>

                                          {/* 
                                          <td>
                                            <button
                                              type="button"
                                              onClick={() => handleEdit(value)}
                                              className={
                                                value.is_boosted == 1
                                                  ? "btn btn-primary btn-sm waves-effect waves-light mb-2 mr-2"
                                                  : "btn btn-secondary btn-sm waves-effect waves-light mb-2 mr-2"
                                              }
                                            >
                                              Boost
                                            </button>
                                          </td>
                                          
                                          */}
                                          <td>
                                          <h5>
{moment(postMatched?.start_date).format("MM/DD/YYYY")}</h5>
                                            {/* {formatDate(value.created_at, "MMM DD, YYYY")}{" "} */}
                                            <small className="text-muted">
                                              {/* {formatDate(value.created_at, "hh:mm A")} */}
                                            </small>
                                          </td>
                                          <td>
                                          <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                      {postMatched.send_job_opportunity_id == null?
                                        <Dropdown.Item onClick={()=>sendMatchedOppertunity(userDetail)}>
                                          Send
                                        </Dropdown.Item>:<Dropdown.Item>
                                          Sent
                                        </Dropdown.Item>}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                          </td>
                                        </tr>
                                      {/* ))} */}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
    </div>
  )
}

export default Job_Post_Match
