import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import * as api from "../../../services/ApiService";
import useConfirm from "../../../hooks/useConfirm";
import Announcement from "../../UI/Announcement"

import * as Yup from "yup";
import moment from "moment/moment";
import { Button } from "react-bootstrap";
import { formatAmount } from "../../../utils/AmountFormatter";
function JobRequest() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const isBoostedJobs = location.pathname.includes("boosted-jobs");
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);


  const [showJobFilterDropdown, setJobFilterDropdown] = useState(false);
  const handleToggleJobFilterDropDown = () => {
    setJobFilterDropdown(!showJobFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    job_title: "",
    state_id: 0,
    date_posted: "",
    is_hide: 0,
    order_by: "default",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      Name: "",
      state_id: "",

    };
    setFilters(defaultFilters);
    
    setJobFilterDropdown(false);
  };

  useEffect(() => {
    if (showJobFilterDropdown === false) {
    }
  }, [showJobFilterDropdown]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };


; // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [boostModal, setBoostModal] = useState(false);


  // State for filter values
  const [formData, setformData] = useState({
    user_id: user.id,
    job_id: "",
    amount: "",
    startDate: "",
    endDate: "",
  });
  const [jobRequset,setJobRequest] = useState()
  const [matchedJobRequest,setMatchedJobRequest] = useState()
  const [states,setStates]= useState()
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);
const getMatchedJobRequest = async()=>{
  try{
    const payload ={
      client_id:user.id
    }
    const apiData = await api.postMethod(payload,"client/job-requests/get-matched-job-request");
    if(apiData){
      console.log(apiData)
      setMatchedJobRequest(apiData.data)
    }
  }
  catch(error){
    console.log(error)
  }
}
const getJobRequest = async()=>{
  try{
    const payload ={
      client_id:user.id
    }
    const apiData = await api.postMethod(payload,"client/job-requests/get-all-job-request");
    if(apiData){
      setJobRequest(apiData.data)
    }
  }
  catch(error){
    console.log(error)
  }
}
useEffect(()=>{
  getMatchedJobRequest()
  getJobRequest()
},[])
  // const { confirm } = useConfirm();


  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: `/` + urlSlug + `/dashboard` },
    { text: isBoostedJobs ? "Boosted Jobs" : "Job Request", link: null },
  ];

  const sendMatchedOppertunity = async(value)=>{
    try{
      const payload ={
        client_id:user.id,
        user_id:value.user_id,
        job_request_id:value.id,
        job_id:value.job_id
      }
      const apiData = await api.postMethod(payload,"client/job-requests/send-job-opportunity");
      if(apiData){
        // console.log(apiData.data)
        setFilters({
          job_title: "",
          state_id: 0,
          date_posted: "",
          is_hide: 0,
          order_by: "default",
        })
      toast.success("Job Request Send Successfully")

        getMatchedJobRequest()
      }
    }
    catch(error){
      console.log(error)
    }
  }

  const handleSubmit=async(e)=>{
    e.preventDefault()
    try{
      const payload={
        client_id:user.id,
        // state_id:filters.state_id
      }
const apiData = await api.postMethod(payload,`client/job-requests/get-all-job-request?state_id=${filters?.state_id=="undefined"?" ":filters?.state_id}&name=${filters?.job_title=="undefined"? 0 :filters?.job_title}&is_hide=${filters.is_hide}`)
const apiData2 = await api.postMethod(payload,`client/job-requests/get-matched-job-request?state_id=${filters?.state_id=="undefined"?" ":filters?.state_id}&name=${filters?.job_title=="undefined"? 0 :filters?.job_title}&is_hide=${filters.is_hide}`)

// console.log("data",apiData.data)
    if(apiData){
      setJobRequest(apiData.data)
      setMatchedJobRequest(apiData2.data)
      handleToggleJobFilterDropDown()
    }
    }
    catch(error){
      console.log(error)
    }
  }
  const hideJobRequest = async(value)=>{
    try{
      const payload={
        client_id:user.id,
        job_request_id:value.id,
        is_hide:filters.is_hide==0?1:0
      }
      const apiData = await api.postMethod(payload,"client/job-requests/hide-job-request")
      if(apiData){
        toast.success(filters.is_hide==0?"Job opportunity Successfully Hidden ":"Job opportunity Un-hide Successfully");
        getMatchedJobRequest()
        getJobRequest()
        setFilters({
          job_title: "",
          state_id: 0,
          date_posted: "",
          is_hide: 0,
          order_by: "default",
        })
      }
    }
    catch(error){
      console.log(error)
    }
  }

  const [openStateModal, setOpenStateModal] = useState(false);

  const toggleStateModal = () => {
    setOpenStateModal(!openStateModal);

    if (!openStateModal) {
      // Reset validation errors
      // setErrors({});
    }
  };
  // console.log(user)
const [sendSMSData,setSendSMSData]= useState()
const [message,setMessage] = useState()
console.log("sendSMSData",sendSMSData,  )
const sendSMS =async()=>{
  try{
      const payload={
        client_id:user.id,
        client_name:user?.name,
        user_id:sendSMSData?.user_id,
        job_request_id:sendSMSData?.id,
        message:message
      }
      console.log(payload)
      const apiData = api.postMethod(payload,"client/job-requests/send-job-request-message")
      if(apiData){
        toggleStateModal()
        toast.success("Message Successfully Send")
      }

  }
  catch(error){
    console.log(error)
  }
}
const [openStateModal2, setOpenStateModal2] = useState(false);
const [cityState, setCityState] = useState();

const toggleStateModal2 = () => {
  setOpenStateModal2(!openStateModal2);

  if (!openStateModal2) {
    // Reset validation errors
    // setErrors({});
  }
};
const DisplayValues = (values, maxDisplay = 3) => {
  // console.log(values);
  if (!values || values.length === 0) return <span>No Location Found</span>;
  const displayText =
    values.length <= maxDisplay
      ? values.join(", ")
      : values.slice(0, maxDisplay).join(", ") + ", ...";
  return displayText;
};

  const announcementModule = user?.role_id === 2 ? "FACILITY_JOB_REQUEST" : user?.role_id === 3 ? "AGENCY_JOB_REQUEST" : undefined;

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            
            <Announcement module={announcementModule} />

            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle    
                title={isBoostedJobs ? "Boosted Jobs" : "Job Requests & Matches"}
                breadcrumbItems={breadcrumbItems}
              />

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            {/* <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleJobFilterDropDown}
                            >
                              Filter and Search Jobs
                              <i className="mdi mdi-chevron-down float-end" />
                            </button> */}
                            {/* <div className={`dropdown-menu ${showJobFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" 
                              onSubmit={handleSubmit}
                              >
                                <div className="mb-2">
                                  <label htmlFor="job_title" className="form-label">
                                   User Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="job_title"
                                    name="job_title"
                                    value={filters.job_title}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="state_id" className="form-label">
                                    Location
                                  </label>
                                  <select
                                    className="form-control"
                                    id="state_id"
                                    name="state_id"
                                    value={filters.state_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {states&&states.map((state) => (
                                      <option key={state.id} value={state.id}>
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>

                                 
                                </div>
                                <div>
                                <label htmlFor="is_hide" className="form-label">
                                    Hide
                                  </label>
                                <select
                                    className="form-control mb-2"
                                    id="is_hide"
                                    name="is_hide"
                                    value={filters.is_hide}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    <option value="1">
                                      Show Hidden Requests
                                    </option>
                                   
                                  </select>
                                </div>
                                <div className="text-end">
                                  <Button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={()=>{getJobRequest();getMatchedJobRequest();handleToggleJobFilterDropDown()}}
                                  >
                                    Reset Filter
                                  </Button>
                                  <button type="submit" className="btn btn-primary">
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                            </div> */}
                          </div>
                          <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="btn btn-outline-light dropdown-toggle w-100 text-start"
                                      >
                                         Filter and Search Jobs
                                         <i className="mdi mdi-chevron-down float-end" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                      
                                     
                                       
                                        <form className="px-2 py-2" 
                              onSubmit={handleSubmit}
                              >
                                <div className="mb-2">
                                  <label htmlFor="job_title" className="form-label">
                                   User Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="job_title"
                                    name="job_title"
                                    value={filters.job_title}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="state_id" className="form-label">
                                    Location
                                  </label>
                                  <select
                                    className="form-control"
                                    id="state_id"
                                    name="state_id"
                                    value={filters.state_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {/* Render the states */}
                                    {states&&states.map((state) => (
                                      <option key={state.id} value={state.id}>
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>

                                 
                                </div>
                                <div>
                                <label htmlFor="is_hide" className="form-label">
                                    Hide
                                  </label>
                                <select
                                    className="form-control mb-2"
                                    id="is_hide"
                                    name="is_hide"
                                    value={filters.is_hide}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    <option value="0">
                                      Show Requests
                                    </option>
                                    <option value="1">
                                      Show Hidden Requests
                                    </option>
                                    {/* <option value="0">
                                      Show Normal Requests
                                    </option> */}
                                    {/* Render the states */}
                                   
                                  </select>
                                </div>
                                <div className="text-end">
                                  <Button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={()=>{getJobRequest();getMatchedJobRequest();handleToggleJobFilterDropDown()}}
                                  >
                                    Reset Filter
                                  </Button>
                                  <button type="submit" className="btn btn-primary">
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                                        
                                      </Dropdown.Menu>
                                    </Dropdown>
                        </div>

                        {/* end col*/}
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>



              {/* end row */}
            </div>
            <div>

            <>
                     {matchedJobRequest&&matchedJobRequest.length>=1?<h4 style={{fontWeight:"700",padding:"1vw"}}>Job Matches</h4>:""}   

                          {/* {Object.entries(jobs).map(([key, value]) => ( */}
                          {matchedJobRequest&&matchedJobRequest.map((value)=>{ return <>
                            <div
                              className="card mb-3 custom-box-shadow  position-relative"
                              key={value.id}
                            >
                              <div className="card-body">
                                <div className="row align-items-center">
                                <div className="d-flex">
                                <div className=" d-flex col-sm-10 align-items-center">
                                <div className="mr-4">
                                <h5 className="mt-0 pt-2 font-16 ">
                                      <Link to={`${value.id}`} className="">
                                        {value?.user_name}{" "}
                                        Title
                                      </Link>
                                      <span style={{marginLeft:"0.5vw"}}>
                                      Matched to <Link to={`/client/job/${value.job_unique_id}`}>{value?.title}</Link>  Click here to send this opportunity. 
                                      </span>
                                      
                                      {/* <span className=" text-muted font-14 mb-1">
                                         ({value.company_name}) 
                                         company_name
                                      </span> */}
                                      {/* 
                                      <span className="mb-0 text-muted">
                                        <small>(#{value.unique_id})</small>
                                      </span>
                                      */}
                                    </h5>
                                </div>
                                    
                                    {value.send_job_opportunity_id == null?<Button onClick={()=>sendMatchedOppertunity(value)} style={{marginLeft:"1vw"}}>Send </Button>:<Button  style={{marginLeft:"1vw",background:"#00b8ff",border:"none"}}>Sent </Button> }
                                  </div>
                                  <div>
                                  Posted {moment(value.created_at).format("MM/DD/YYYY")}
                                  </div>
                                </div>
                                 
                                  <div className="col-sm-4">
                                    <div className="d-flex align-items-start">
                                      {/* <Link to={`/user/job/` + 1} className=""> */}
                                        {value.profile_pic && (
                                          <img
                                            src={value.profile_pic}
                                            className="d-flex align-self-center me-3 rounded-circle"
                                            alt="user-img"
                                            height={64}
                                            width={64}
                                          />
                                          )}{" "}
                                      {/* </Link> */}

                                      <div className="w-100">
                                        <p className="mb-1">
                                          <b>Profession : </b>{" "}
                                          {value.profession !== null ? value.profession : "-"}

                                          {/* {value.state_code !== null && value.city_name !== null
                                            ? value.city_name + ", " + value.state_code
                                            : value.state_code !== null
                                            ? value.state_code
                                            : value.city_name !== null
                                            ? value.city_name
                                            : "N/A"} */}
                                        </p>
                                        <p className="mb-0">
                                          <b>Specialty : </b>{" "}
                                          {value.specialty !== null ? value.specialty : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Shift : </b> 
                                      {value.shift_title || "-"}
                                    </p>
                                    <p className="mb-0 d-flex">
                                      <b>Locations : </b>
                                      <p>
                                {value?.state_city_data[0]?.state} |{" "}
                                {DisplayValues(
                                  value?.state_city_data[0]?.cities
                                )}{" "}
                                {value?.state_city_data.length > 1 ? (
                                  <span
                                    onClick={() => {
                                      setCityState(value);
                                      toggleStateModal2();
                                    }}
                                    style={{
                                      marginLeft: "2vw",
                                      background: "#43bfe5",
                                      padding: 3,
                                      fontSize: "12px",
                                      borderRadius: 50,
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                  >
                                    +
                                    {value?.state_city_data.length -
                                      1}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>

                                     {/* ${value.pay_rate !== null ? value.pay_rate : "-"} */}
                                      {/* {value.show_pay_rate == 0 ? (
                                        <>
                                          <span
                                            onClick={() => toggleModal(value.id)}
                                            className="cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Contact Us
                                          </span>
                                        </>
                                      ) : value.salary_start_range ? (
                                        <>
                                          ${formatAmount(value.salary_start_range)}{" "}
                                          {value.salary_type}
                                        </>
                                      ) : (
                                        "N/A"
                                      )} */}
                                    </p>
                                  </div>
                                  <div className="col-sm-3">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Salary : </b> 
                                      ${formatAmount(value.pay_rate) || "N/A"}{value.pay_type || "N/A"}
                                    </p>
                                    <p className="mb-0">
                                      <b>Start Date : </b>
                                       {value?.start_date}
                                    </p>
                                  </div>
                                  <div className="col-md-1">
            <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                      {
                                            filters.is_hide == 0?<Dropdown.Item onClick={()=>hideJobRequest(value)}>
                                          Hide
                                        </Dropdown.Item>:<Dropdown.Item onClick={()=>hideJobRequest(value)}>
                                          Un-hide
                                        </Dropdown.Item>
                                          }
                                          {/* <Dropdown.Item onClick={()=>hideJobRequest(value)}>
                                          Hide
                                        </Dropdown.Item> */}
                                          <Dropdown.Item onClick={()=>{toggleStateModal();setSendSMSData(value)}} >
                                           Message
                                          </Dropdown.Item>
                                        <Dropdown.Item onClick={()=>navigate("/client/sent_job_request",{state:{userId:value.user_id,jobRequest:value?.id,UserData:value}})}>
                                        Send Opportunity
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
          </div>
                                </div>
                              </div>
                              {/* {value.is_boosted == 1 && (
                                <div className="ribbon ribbon-triangle ribbon-top-end border-primary">
                                  <div class="ribbon-icon mt-n3 me-0">
                                    <i class="fas fa-star fs-2 text-white font-18"></i>
                                  </div>
                                </div>
                              )} */}
                            </div>
                          </>})}
                            
                          {/* ))} */}
                        </>
            </div>
            <div>

            <>
                        {jobRequset&&jobRequset.length>=1?<h4 style={{fontWeight:"700",padding:"1vw"}}>Job Request Listings</h4>:""}

                          {/* {Object.entries(jobs).map(([key, value]) => ( */}
                          {jobRequset&&jobRequset.map((value)=>{ return <>
                            <div
                              className="card mb-3 custom-box-shadow  position-relative"
                              key={value.id}
                            >
                              <div className="card-body">
                                <div className="row align-items-center">
                                <div className="d-flex">
                                <div className=" d-flex col-sm-10 align-items-center">
                                <div className="mr-4">
                                <h5 className="mt-0 pt-2 font-16 ">
                                      <Link to={`${value.id}`} className="">
                                        {value?.user_name}{" "}
                                        Title
                                      </Link>
                                      {/* <span className=" text-muted font-14 mb-1">
                                         ({value.company_name}) 
                                         company_name
                                      </span> */}
                                      {/* 
                                      <span className="mb-0 text-muted">
                                        <small>(#{value.unique_id})</small>
                                      </span>
                                      */}
                                    </h5>
                                </div>
                                  
                                    <Button onClick={()=>navigate("/client/sent_job_request",{state:{id:value.user_id,jobRequest:value?.id,UserData:value,userId:value.user_id}})} style={{marginLeft:"1vw"}}>Send Job Opportunity</Button>
                                  </div>
                                  <div>
                                  Posted {moment(value.created_at).format("MM/DD/YYYY")}
                                  </div>
                                </div>
                                 
                                  <div className="col-sm-4">
                                    <div className="d-flex align-items-start">
                                      {/* <Link to={`/user/job/` + 1} className=""> */}
                                        {value.profile_pic ?(
                                          <img
                                            src={value.profile_pic}
                                            className="d-flex align-self-center me-3 rounded-circle"
                                            alt="user-img"
                                            height={64}
                                            width={64}
                                          />
                                          ):
                                          <img
                                            src={process.env.PUBLIC_URL + "/assets/images/fav-icon.jpg"}
                                            className="d-flex align-self-center me-3 rounded-circle"
                                            alt="user-img"
                                            height={64}
                                            width={64}
                                          />
                                          }
                                      {/* </Link> */}

                                      <div className="w-100">
                                        <p className="mb-1">
                                          <b>Profession : </b>{" "}
                                          {value.profession !== null ? value.profession : "-"}

                                          {/* {value.state_code !== null && value.city_name !== null
                                            ? value.city_name + ", " + value.state_code
                                            : value.state_code !== null
                                            ? value.state_code
                                            : value.city_name !== null
                                            ? value.city_name
                                            : "N/A"} */}
                                        </p>
                                        <p className="mb-0">
                                          <b>Specialty : </b>{" "}
                                          {value.specialty !== null ? value.specialty : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Shift : </b> 
                                      {value.shift_title || "-"}
                                    </p>
                                    <p className="mb-0 d-flex">
                                      <b>Locations : </b>
                                      <p>
                                {value?.state_city_data[0]?.state} |{" "}
                                {DisplayValues(
                                  value?.state_city_data[0]?.cities
                                )}{" "}
                                {value?.state_city_data.length > 1 ? (
                                  <span
                                    onClick={() => {
                                      setCityState(value);
                                      toggleStateModal2();
                                    }}
                                    style={{
                                      marginLeft: "2vw",
                                      background: "#43bfe5",
                                      padding: 3,
                                      fontSize: "12px",
                                      borderRadius: 50,
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                  >
                                    +
                                    {value?.state_city_data.length -
                                      1}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>

                                     {/* ${value.pay_rate !== null ? value.pay_rate : "-"} */}
                                      {/* {value.show_pay_rate == 0 ? (
                                        <>
                                          <span
                                            onClick={() => toggleModal(value.id)}
                                            className="cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Contact Us
                                          </span>
                                        </>
                                      ) : value.salary_start_range ? (
                                        <>
                                          ${formatAmount(value.salary_start_range)}{" "}
                                          {value.salary_type}
                                        </>
                                      ) : (
                                        "N/A"
                                      )} */}
                                    </p>
                                  </div>
                                  <div className="col-sm-3">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Salary : </b> 
                                      ${formatAmount(value.pay_rate) || "N/A"}{value.pay_type || "N/A"}
                                    </p>
                                    <p className="mb-0">
                                      <b>Start Date : </b>
                                       {value?.start_date}
                                    </p>
                                  </div>
                                  <div className="col-md-1">
            <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                          <Dropdown.Item onClick={()=>navigate(`/client/job-request/${value.id}`)} >
                                          Review Details
                                          </Dropdown.Item>
                                          {
                                            filters.is_hide == 0?<Dropdown.Item onClick={()=>hideJobRequest(value)}>
                                          Hide
                                        </Dropdown.Item>:<Dropdown.Item onClick={()=>hideJobRequest(value)}>
                                          Un-hide
                                        </Dropdown.Item>
                                          }
                                          
                                          <Dropdown.Item onClick={()=>{toggleStateModal();setSendSMSData(value)}} >
                                           Message
                                          </Dropdown.Item>
                                        <Dropdown.Item onClick={()=>navigate("/client/sent_job_request",{state:{userId:value.user_id,jobRequest:value?.id,UserData:value}})}>
                                        Send Opportunity
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
          </div>
                                </div>
                              </div>
                              {/* {value.is_boosted == 1 && (
                                <div className="ribbon ribbon-triangle ribbon-top-end border-primary">
                                  <div class="ribbon-icon mt-n3 me-0">
                                    <i class="fas fa-star fs-2 text-white font-18"></i>
                                  </div>
                                </div>
                              )} */}
                            </div>
                          </>})}
                            
                            
                          {/* ))} */}
                        </>
            </div>
            {/* container */}
            <div >

        </div>
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
      <Modal
        show={openStateModal}
        onHide={toggleStateModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Send Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
<textarea onChange={(e)=>setMessage(e.target.value)} style={{width:"100%",height:"10vw"}}/>
            </div>
          </div>
          <div className="d-flex" style={{alignItems:"center",justifyContent:"center"}}>
          <Button onClick={()=>sendSMS()}>
          Send Message
          </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openStateModal2}
        onHide={toggleStateModal2}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">State / Cities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
              <p>
                <strong style={{ marginBottom: "2vw" }}>Locations</strong>
              </p>
              {cityState &&
                cityState?.state_city_data.map((data) => (
                  <>
                    <p>
                      <strong>{data.state}</strong> |{" "}
                      {data && data.cities.map((city) => <span> {city},</span>)}
                    </p>
                  </>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JobRequest;
