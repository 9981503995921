import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Layout/PageTitle";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { currentRole } from "../../Layout/HelmetComponent";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import usePlanStore from "../../../store/usePlanStore";
import { getBillingCycleLabel, billingCycleMap2 } from "../../../utils/billingCycleHelper";
import { apiRequest } from "../../../services/ApiRequest";
import useSubscriptionStore from "../../../store/useSubscriptionStore";
import { useConfirm3 } from "../../../hooks/useConfirm";

function Checkout() {
  const navigate = useNavigate();
  const location = useLocation();
  const {data: plans} = usePlanStore();
  const { data: subscription } = useSubscriptionStore();
  const { confirm3 } = useConfirm3();
  const [planDate, setPlanDate] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedBillingCycle, setSelectedBillingCycle] = useState({
    type: null,
    id: null,
    price: null
  });
  const billingCycleLabel = getBillingCycleLabel(selectedBillingCycle?.type);

  const queryParams = new URLSearchParams(location.search);
  // const payerId = queryParams.get('PayerID');
  const subscriptionId = queryParams.get('subscription_id') ?? queryParams.get('token');
  const planId = location?.state?.plan_id ?? queryParams.get('plan_id');
  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Checkout", link: null },
  ];
  console.log(selectedPlan);
  const productType = selectedPlan?.plan_type === "pay_as_you_go" ? 'Order' : 'Subscription';

  const formatDate = (date) => (date ? moment(date).format("MMMM-DD-YYYY") : "");

  const handleCheckOut = async () => {
    try {
      if (!selectedPlan || !selectedBillingCycle) {
        console.error("Either selected plan or billing cycle not found");
        return;
      }

      // Check if user has an active subscription and is downgrading
      let isDowngrading = false;
      if (subscription && subscription.status === "active") {
        isDowngrading = selectedPlan.plan_id < subscription.plan_id;
      }

      // Ask for confirmation only if downgrading
      const confirmed = isDowngrading ? await confirm3() : { isConfirmed: true };
      if (!confirmed.isConfirmed) return; // Stop execution if user cancels

      // Proceed with checkout
      const path = "client/subscription/create";
      const payload = {
        plan_id: selectedPlan.plan_id,
        billing_cycle_type: selectedBillingCycle.type
      };

      const response = await apiRequest(path, "post", payload);

      if (response.success) {
        window.location.href = response.data.approval_url; // Redirect the user
      } else {
        toast.error(response.error);
      }

    } catch (error) {
      toast.error(error?.message);
      console.error("An error occurred during checkout:", error);
    }
  };


  const handleBillingCycleChange = (billingCycleType) => {
    const newBillingCycle = selectedPlan.billing_cycles.find(b => b.type === billingCycleType);
    if(newBillingCycle){
      setSelectedBillingCycle(newBillingCycle);
    }
  }

  useEffect(() => { 
    function calculatePlanDates(billingCycle) {
      const today = new Date();
      const nextDate = new Date();
      if (billingCycle === 'annually') {
        nextDate.setFullYear(today.getFullYear() + 1);
      }
      else {
        nextDate.setMonth(today.getMonth() + 1);
      }
      const formatDate = (date) => date.toISOString().split("T")[0];
      setPlanDate({
        today: formatDate(today),
        nextDate: formatDate(nextDate),
      });
    }
    if(selectedBillingCycle && selectedBillingCycle.type){
      calculatePlanDates(selectedBillingCycle.type) 
    }
  }, [selectedBillingCycle]);

  useEffect(() => {
    if(plans && planId){
      const matchedPlan = plans.find(plan => plan.plan_id === +planId);
      if(matchedPlan){
        setSelectedPlan(matchedPlan);
        setSelectedBillingCycle(matchedPlan.billing_cycles[0]);
      }
    }
  },[planId, plans]);

  useEffect(() => {
    const handleResponse = async(subscriptionId) => {      
      try {
        const path = "client/subscription/response";
        const payload = { paypal_ref_id: subscriptionId };
        const response = await apiRequest(path, "post", payload);
        if(response){
          if(response.success === true){
            toast.info(response.message);
            setTimeout(() => {
              navigate('/client/my-subscription', {
                state: {
                  refetch: true // will refresh my subscription data
                },
              }); 
            }, 3000); // 3000 milliseconds = 3 seconds
          }
  
          if(response.success === false){
            toast.error(response.error);
          }
        }
      } catch (error) {
        toast.error(error?.message);
        console.error("An error occurred during checkout:", error);
      }
    }


    if(subscriptionId){
      handleResponse(subscriptionId);
    }
  }, [subscriptionId, navigate]);

  console.log("subscriptionId", subscriptionId);
  console.log("planId", planId);
  console.log("planType", selectedPlan?.plan_type);

  return (
    <Layout>
      <div className="container-fluid">
        <PageTitle title="Checkout" breadcrumbItems={breadcrumbItems} />
        <div className="row mb-3" style={{ justifyContent: "space-evenly" }}>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mb-3">{productType}</h4>

                <div className="pb-2">
                  <label className="form-label">Package</label>
                  <select className="form-control">
                    <option value="">{selectedPlan?.plan_name} (${selectedBillingCycle?.price} / {billingCycleLabel})</option>
                  </select>
                </div>

                {selectedPlan && selectedPlan.plan_type === "recurring" && (
                    <div className="pb-2">
                      <label className="form-label">Type</label>
                      <select className="form-control"
                        onChange={(e) => handleBillingCycleChange(e.target.value)}
                      >
                        {selectedPlan.billing_cycles.map((billingCycle, index) => {
                            return <option key={index} value={billingCycle.type}>{getBillingCycleLabel(billingCycle.type, billingCycleMap2)}</option>
                        })}
                        {/* <option value="monthly">Monthly</option>
                        <option value="annually">Annually</option> */}
                      </select>
                    </div>
                )}
                </div>
            </div>
          </div>
          {selectedPlan && selectedPlan.plan_type === "subscription" && (
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Subscription Validity</h4>

                  <div className="pb-2">
                    <label className="form-label">Subscription Start Date</label>
                    <input type="text" className="form-control" disabled value={formatDate(planDate?.today)} />
                  </div>

                  <div className="pb-2">
                    <label className="form-label">Subscription End Date</label>
                    <input type="text" className="form-control" disabled value={formatDate(planDate?.nextDate)} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <h4 className="header-title mb-3">{productType} Payment Details</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-centered mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Package</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{selectedPlan?.plan_name}</th>
                    <td>1</td>
                    <td>${selectedBillingCycle?.price}</td>
                    <td>${selectedBillingCycle?.price}</td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={3} className="text-right">
                      Sub Total :
                    </th>
                    <td>
                      <div className="font-weight-bold">${selectedBillingCycle?.price}</div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={3} className="text-right">
                      Total :
                    </th>
                    <td>
                      <div className="font-weight-bold">${selectedBillingCycle?.price}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="text-end mb-3">
          <Button onClick={handleCheckOut} class="btn btn-primary">
            <i class="mdi mdi-cart-plus mr-1"></i> Proceed to Checkout
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default Checkout;
