import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/dateFormat";
import PageTitle from "../../Layout/PageTitle";
import useConfirm from "../../../hooks/useConfirm";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import { Table } from "quill-table";
import Quill from "quill";

// Quill.register("modules/table", Table);
const AdminBlogs = () => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      ["direction", { align: [] }],
      [{ size: ["small", false, "large", "huge"]}],
      [{ header: [1, 2, 3, false] }], // Header dropdown
      ["bold", "italic", "underline", "strike"], // Formatting buttons
      [{ list: "ordered" }, { list: "bullet" }], // List options
      [{ color: [] }, { background: [] }], // Text and background colors
      ["link", "image"], // Link and image options
      ["clean"], // Clear formatting
      // ["undo", "redo"], // Add undo and redo buttons to the toolbar
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
    "background",
    "link",
    "image",
    "size",
    "font"
  ];
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    fetchComplianceFiles();
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-blogs");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    title: null,
    short_description: null,
    description: null,
    profile_pic: null,
    meta_title: null,
    meta_description: null,
  });

  const handleImageChange = (e) => {
    setformData({
      ...formData,
      profile_pic: e.target.files[0], // Update image field with the selected file
    });
  };

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        title: null,
        short_description: null,
        description: null,
        profile_pic: null,
        meta_title: null,
        meta_description: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      title: record.title,
      short_description: record.short_description,
      description: record.description,
      profile_pic: record.profile_pic,
      meta_title: record.meta_title,
      meta_description: record.meta_description,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "admin/delete-blog");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
    short_description: Yup.string().required("Short Description is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-blog");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e,discription) => {
    if(discription=="discription"){
      setformData({ ...formData, description: e });
    }else{

      const { name, value } = e.target;
      setformData({ ...formData, [name]: value });
      
      validateFields(name, value);
    }
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "admin/update-blog-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Blogs", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Blogs" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between mb-2">
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value="1">Active</option>
                            <option value="0">In-Active</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={toggleUploadModal}
                    >
                      <i className="fa fa-plus mr-1" /> Add New
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="4">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="4">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(complianceFiles).map(([key, value]) => (
                              <tr key={value.id}>
                                <td>{value.title}</td>
                                <td>
                                  {value.status == 1 && (
                                    <label className="badge badge-soft-success">Active</label>
                                  )}
                                  {value.status == 0 && (
                                    <label className="badge badge-soft-warning">In-Active</label>
                                  )}
                                </td>
                                <td>
                                  {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                  <small className="text-muted">
                                    {formatDate(value.created_at, "hh:mm A")}
                                  </small>
                                </td>
                                <td>
                                  {value.status == 0 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="In-Active"
                                        onClick={() => updateStatus(value.id, 1)}
                                      >
                                        <i className="mdi mdi-close-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}
                                  {value.status == 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Active"
                                        onClick={() => updateStatus(value.id, 0)}
                                      >
                                        <i className="mdi mdi-check-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}

                                  <button
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Edit Blog"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>

                                  {/* 
                                  <Link
                                    to={`/admin/user-profile/` + value.unique_id}
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-4"
                                    data-tooltip-content="View Blog"
                                  >
                                    <i className="mdi mdi-eye" />
                                  </Link>
                                  <Tooltip id="mt-4"></Tooltip>
*/}

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-5"
                                    data-tooltip-content="Remove Blog"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-5"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  defaultValue={formData.title !== null ? formData.title : ""}
                />
                {errors.title && <span className="error">{errors.title}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="short_description">
                  Short Description
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="short_description"
                  name="short_description"
                  onChange={handleChange}
                  defaultValue={
                    formData.short_description !== null ? formData.short_description : ""
                  }
                ></textarea>
                {errors.short_description && (
                  <span className="error">{errors.short_description}</span>
                )}
              </div>

              <div className="col-md-12 mb-5">
                <label className="form-label" htmlFor="description">
                  Description
                </label>

                <ReactQuill
                
      value={formData.description}
      onChange={(e)=>handleChange(e,"discription")}
      modules={modules}
      formats={formats}
      style={{height:"20vw"}}
      className="custom-quill-editor "
    />
                {/* <CKEditor
                  config={{
                    className: "custom-ckeditor",
      }}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setErrors({ ...errors, description: "" });
                    setformData({ ...formData, description: data });
                  }}
                  data={formData.description}
                /> */}
                {errors.description && <span className="error">{errors.description}</span>}
              </div>

              <div className="mb-2 col-md-12">
                <label className="form-label" htmlFor="image">
                  Image
                </label>
                <div className="input-group">
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    id="profile_pic"
                    name="profile_pic"
                    onChange={handleImageChange}
                  />
                  {formData.profile_pic && (
                    <div className="input-group-prepend">
                      <span className="input-group-text p-1">
                        <img src={formData.profile_pic} alt="" className="float-end " height={32} />
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="meta_title">
                  Meta Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="meta_title"
                  name="meta_title"
                  onChange={handleChange}
                  defaultValue={formData.meta_title !== null ? formData.meta_title : ""}
                />
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="meta_description">
                  Meta Description
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="meta_description"
                  name="meta_description"
                  onChange={handleChange}
                  defaultValue={formData.meta_description !== null ? formData.meta_description : ""}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default AdminBlogs;
