import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";

function Jobs({ fetchJobData, filters, onUpdateFilters, curPage }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const navigate = useNavigate();
  const [localFilters, setLocalFilters] = useState(filters);

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      job_title: "",
      state_id: 0,
      no_of_opening: "",
      profession_id: 0,
      specialty_id: 0,
      listing_type: "all",
      job_type: "",
      order_by: "default",
    };
    setLocalFilters(defaultFilters);
    onUpdateFilters(defaultFilters);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters({
      ...localFilters,
      [name]: value,
    });
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    if (curPage == "jobs") navigate(`/user/jobs`);
    else if (curPage == "savedJobs") navigate(`/user/saved-jobs`);
    onUpdateFilters(localFilters);
  };

  const [states, setStates] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specialties, setspecialties] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchSpecialtyData = async () => {
      try {
        const apiData = await api.getMethod("get-specialties/" + localFilters.profession_id);

        setspecialties(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchSpecialtyData();
  }, [localFilters.profession_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [professions, setProfessions] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [employmentTypes, setEmploymentTypes] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    setTimeout(() => {
      fetchJobData();
      },200)
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <form onSubmit={handleFilterSubmit}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3 mb-2">
                    <label htmlFor="job_title" className="form-label">
                      Keyword
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="job_title"
                      name="job_title"
                      value={localFilters.job_title}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-3 mb-2">
                    <label htmlFor="state_id" className="form-label">
                      Location
                    </label>
                    <select
                      className="form-control"
                      id="state_id"
                      onChange={handleInputChange}
                      name="state_id"
                      value={localFilters.state_id}
                    >
                      <option value="" key="">
                        Please select
                      </option>
                      {/* Render the states */}
                      {states &&
                        states.length > 0 &&
                        states.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-3 mb-2">
                    <label htmlFor="no_of_opening" className="form-label">
                      No. of Openings
                    </label>
                    <select
                      className="form-control"
                      id="no_of_opening"
                      onChange={handleInputChange}
                      name="no_of_opening"
                      value={localFilters.no_of_opening}
                    >
                      <option value="">Please select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3+">3+</option>
                    </select>
                  </div>
                  <div className="col-md-3 mb-2">
                    <label htmlFor="profession_id" className="form-label">
                      Professions
                    </label>
                    <select
                      className="form-control"
                      id="profession_id"
                      onChange={handleInputChange}
                      name="profession_id"
                      value={localFilters.profession_id}
                    >
                      <option value="" key="">
                        Please select
                      </option>
                      {/* Render the professions */}
                      {professions &&
                        professions.length > 0 &&
                        professions.map((profession) => (
                          <option key={profession.id} value={profession.id}>
                            {profession.profession}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-3 mb-2">
                    <label htmlFor="specialty_id" className="form-label">
                      Specialty
                    </label>
                    <select
                      className="form-control"
                      id="specialty_id"
                      onChange={handleInputChange}
                      name="specialty_id"
                      value={localFilters.specialty_id}
                    >
                      <option value="" key="">
                        Please select
                      </option>
                      {/* Render the specialties */}
                      {specialties &&
                        specialties.length > 0 &&
                        specialties.map((specialty) => (
                          <option key={specialty.id} value={specialty.id}>
                            {specialty.specialty}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="mb-2 col-md-3">
                    <label htmlFor="job_type" className="form-label">
                      Job Type
                    </label>
                    <select
                      id="job_type"
                      className="form-control"
                      name="job_type"
                      value={localFilters.job_type}
                      onChange={handleInputChange}
                    >
                      <option value="" key="">
                        Please select
                      </option>
                      {/* Render the professions */}
                      {employmentTypes.map((emp) => (
                        <option key={emp.id} value={emp.id}>
                          {emp.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  {curPage == "jobs" && (
                    <div className="col-md-3 mb-2">
                      <label htmlFor="listing_type" className="form-label">
                        Job Selection
                      </label>
                      <select
                        className="form-control"
                        id="listing_type"
                        onChange={handleInputChange}
                        name="listing_type"
                        value={localFilters.listing_type}
                      >
                        <option value="all">All Jobs</option>
                        <option value="saved">Saved Jobs</option>
                      </select>
                    </div>
                  )}

                  <div className="mb-2 col-md-3">
                    <label htmlFor="order_by" className="form-label">
                      Order By
                    </label>
                    <select
                      className="form-control"
                      id="order_by"
                      name="order_by"
                      value={localFilters.order_by}
                      onChange={handleInputChange}
                    >
                      <option value="default" key="default">
                        Default
                      </option>
                      <option value="highest" key="highest">
                        Highest Pay First
                      </option>
                      <option value="lowest" key="lowest">
                        Lowest Pay First
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-footer border-top">
                <div className="row">
                  <div className="col-md-1">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-primary btn-block w-100"
                    />
                  </div>

                  <div className="col-md-1">
                    <button
                      type="button"
                      className="btn btn-secondary btn-block w-100"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
